
// Your variable overrides
$sidebar-bg-color: #748190;
$highlight-color: #36B8B4;
$icon-bg-color: #748190;
$sidebar-color: #EFEEEF;
$sidebar-width: "325px";
$submenu-bg-color: #748190;
$submenu-indent: 50px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
