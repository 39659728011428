.logoHoushares {
    width: 400px;
}

.avatarImg {
    width: 300px;
    height: 300px;
    border-radius: 150px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

.form {
    color: #FFFFFF;
    margin-top: 10px;
}

.buttonForm {
    background-color: #36B8B4;
    border-color: #36B8B4;
    border-radius: 50%;
    padding: 10px;
    font-size: 25px;
}

.divBtn {
    align-items: flex-end;
    display: flex;
    justify-content: right;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, -40%);
    right: 0;
    padding-right: 340px !important;
}

@media (max-width: 1400px) {

    .divBtn {
        align-items: flex-end;
        display: flex;
        justify-content: right;
        position: absolute;
        bottom: 0;
        transform: translate(-50%, -40%);
        right: 0;
        padding-right: 280px !important;
    }
}

@media (max-width: 1000px) {

    .divBtn {
        align-items: flex-end;
        display: flex;
        justify-content: right;
        padding-right: 100px !important;
    }
}


@media (max-width: 768px) {

    .avatarImg {
        width: 300px;
        height: 300px;
        border-radius: 150px;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
    }

    .form {
        margin-top: 10px;
    }

    .divBtn {
        align-items: flex-end;
        display: flex;
        justify-content: right;
        padding-right: 50px !important;
    }

    .logoHoushares {
        width: 200px;
    }
}

@media (max-width: 500px) {

    .divBtn {
        align-items: flex-end;
        display: flex;
        justify-content: right;
        padding-right: 50px !important;
    }
}

.button-container {
    color: black!important;
    background-color: gray!important;
    border-radius: 20px!important;
    border-color: black!important;
    width: 40px!important;
    height: 40px!important;
}
