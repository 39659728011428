
@media (min-width: 769px) {
    .logoHoushares {
        display: none;
    }

    .logoBigHouseShares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 60px;
    }
}

@media (max-width: 768px) {

    .logoBigHouseShares {
        display: none;
    }

    .logoHoushares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 100px;
    }
}
.logIn {
    align-self: flex-end;
    text-align: end;
    color: white;
}
