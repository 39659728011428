
.logIn {
    align-self: flex-end;
    text-align: end;
    color: #353939;
}

@media (min-width: 769px) {

    .logoHoushares {
        display: none;
    }

    .logoBigHouseShares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 60px;
    }

    .bannercontainer {
        height: 500px;
        width: 600px;
    }

    .imagesSignUp {
        text-align: center;
        height: 580px;
        width: 750px !important;
        margin-top: 20px;
    }

    .imageBanner1 {
        width: 100%;
        height: auto;
    }

    .imageBanner2 {
        width: 125px;
        height: 240px;
        position: absolute;
        left: 28px;
        top: 15px
    }

    .imageBanner3 {
        width: 125px;
        height: 240px;
        position: absolute;
        right: 28px;
        z-index: 2;
    }

    .imageBanner4 {
        width: 125px;
        height: 240px;
        position: absolute;
        right: -93px;
        top: 15px
    }

}

@media (max-width: 768px) {

    .logoBigHouseShares {
        display: none;
    }

    .logoHoushares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 100px;
    }

    .imagesSignUp {
        text-align: center;
        position: relative;
        min-height: 210px;
    }

    .imageBanner1 {
        width: 100%;
        height: auto;
    }

    .imageBanner2 {
        width: 100px;
        height: 200px;
        position: absolute;
        left: -22px;
        top: 15px
    }

    .imageBanner3 {
        width: 100px;
        height: 200px;
        position: absolute;
        right: -22px;
        z-index: 2;
    }

    .imageBanner4 {
        width: 100px;
        height: 200px;
        position: absolute;
        right: -110px;
        top: 15px
    }



    .signUp {
        align-self: flex-start;
        margin-left: 0;
    }

    @media (max-width: 395px) {
        .imagesSignUp {
            text-align: center;

        }

        .imageBanner1 {
            width: 100%;
            height: auto;
        }

        .imageBanner2 {
            width: 80px;
            height: 180px;
            position: absolute;
            left: -20px;
            top: 15px
        }

        .imageBanner3 {
            width: 80px;
            height: 180px;
            position: absolute;
            right: -32px;
            z-index: 2;
        }

        .imageBanner4 {
            width: 80px;
            height: 180px;
            position: absolute;
            right: -100px;
            top: 15px
        }
    }

}
