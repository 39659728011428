.slickSlider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    /*"@media (min-width: 768px)" {*/
    /*    SlickCaption {*/
    /*        display: block !important;*/
    /*    }*/
}

.itemCarousel{
    text-align: -webkit-center;
}