
.backgroundPage{
    background-color: #353939;
    background: linear-gradient(0deg, #353939 65%, #C4C4C4 30%)
}


@media (max-width: 768px) {

    .backgroundPage {
        background: linear-gradient(0deg, #353939 70%, #C4C4C4 30%)
    }

}