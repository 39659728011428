@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.box {
    transition: opacity 3s;
    animation: fadeIn 3s;
}
