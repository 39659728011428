.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 10px;
    background: #EFEEEF;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
     : none;
    appearance: none;
    width: 100px;
    height: 30px;
    border-radius: 10px;
    background: #36B8B4;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background: #36B8B4;
    cursor: pointer;
}
