

.logoLong {
    width: 400px;
    height: 100px;
}

.dropdownDiv {
    color: #FFFFFF;
    font-size: small;
}

.dropdownToggle {
    background-color: #353939;
    border-color: #353939;
    /*"::hover" {*/
    /*           cursor: pointer;*/
    /*           color: #36B8B4;*/
    /*           background-color: #353939;*/
    /*           border-color:#353939;*/
    /*       }*/

}

.dropdownToggle:hover {
    cursor: pointer;
    background-color: red;
}

dropdownMenu {
    background-color: #353939;
    border-color: #353939;
}

dropdownItem {
    color: #FFFFFF;
    background-color: #353939;
    /*"&:hover, &:focus" {*/
    /*                      cursor: pointer;*/
    /*                      color: #36B8B4;*/
    /*                      backgroundColor: #353939;*/
    /*                  }*/
}

.dropdownItem:hover {
    background-color: red;
}

.playerWrapper {
    width: auto;
    height: auto;
    text-align: -webkit-center;
}

.onboardText {
    color: #FFFFFF;
    text-align: center;
}

.getStartBtn {
    color: #FFFFFF;
    font-size: 35px;
    text-align: center;
}

.modal {
    width: 90vw;
    height: 90vh;
}

@media (max-width: 768px) {

    .logoLong {
        /*margin-top: 20%;*/
        width: 70%;
        height: 100%;
    }

    .dropdownDiv {
        width: 50%;
        text-align: -webkit-right;
    }

    .playerWrapper {
        margin-top: 10%;
        text-align: -webkit-center;
    }
}
