
.colSearch{
    text-align: -webkit-center;
}

.formSearch{
    margin-top: 20px;
    width: 50%;
}

.slickSlider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    /*"@media (min-width: 768px)" {*/
    /*    SlickCaption {*/
    /*        display: block !important;*/
    /*    }*/


}
.slick-slide.slick-center {
    position: relative;
    top: 5px;
    background-color: darkred;
}

.slickSlider.slick-center {
    /*-webkit-transform: scale(1.25);*/
    /*-moz-transform: scale(1.25);*/
    /*transform: scale(1.25);*/
    background-color: darkred;
}

.itemCarousel{
    text-align: -webkit-center;
    cursor: pointer;
}

@media (max-width: 768px) {
    .formSearch{
        margin-top: 20px;
        width: 100%;
    }
}

@media (min-width: 769px) {
    .logoHoushares {
        display: none;
    }

    .logoBigHouseShares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 60px;
    }
}

@media (max-width: 768px) {

    .logoBigHouseShares {
        display: none;
    }

    .logoHoushares {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        height: 100px;
    }
}
