body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #353939 !important;
}

.white-body {
  background-color: #FFFFFF !important;
}

input, button {
  outline: none!important;
  box-shadow: none!important;
}
input:focus {outline:none!important;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.modal-content{
  background-color: transparent !important;
  border-color: transparent !important;
}

.rm-pad {
  padding: 0px !important;
}

.landscape {
  height: 80px;
  width: 130px;
}

.white-text-input:disabled {
  -webkit-text-fill-color: #ffffff!important;
  -webkit-opacity:1;
  opacity:1!important;
  color: #ffffff;
}

.pro-sidebar {
  height: inherit!important;
  min-height: 100vh;
}

.input-gray-style {
  background-color: #748190!important;
  color: white!important;
  border-radius: 12px!important;
  height: 37px!important;
  opacity: 1!important;
}
